
@import './components/ngx-contextmenu';

.modal {
  &.modal-flex-card {
    .modal-dialog {
      overflow: visible;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: visible;
    }
  }

  &.modal-min-form {
    .modal-dialog {
      max-width: 441px;
      min-width: 441px;
    }
  }

  &.modal-big-form {
    .modal-dialog {
      &.modal-dialog-centered {
        min-width: 800px;
        max-width: 800px;
        max-height: calc(100% - 60px);
      }
    }
  }

  &.modal-big-detail-form {
    .modal-dialog {
      &.modal-dialog-centered {
        min-width: 800px;
        max-width: 800px;
        height: 620px;
        max-height: calc(100% - 60px);
      }
    }
  }
}

.g-card,
.white-rounded-background {
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0 1px 5px 0 var(--box-shadow);
}

.ag-body-vertical-scroll,
.ag-body-vertical-scroll-viewport,
.ag-body-vertical-scroll-container {
  width: 10px !important;
  min-width: 10px !important;
  max-width: 10px !important;
}
.ag-body-vertical-scroll-viewport {
  scrollbar-color: var(--scroll) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }

  &::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 5px grey !important;
    border-radius: 0px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scroll) !important;
    border-radius: 5px !important;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scroll-active) !important;
    cursor: pointer !important;
  }
}
