
.grid-context-menu {
  max-width: 275px - (11px + 32px) !important;
  padding: 0 !important;
  box-shadow: none !important;
  margin: 0 !important;
  border: 0 !important;
}
.cdk-overlay-pane.ngx-contextmenu-overlay {
  padding: 28px 11px 28px 32px !important;
}
.ngx-contextmenu .ngx-context-menu-item[role=menuitem] {
  white-space: normal !important;
  text-align: left !important;
  padding-left: 0 !important;
  padding-right: 14px !important;
  cursor: pointer;
}